$(document).ready(function () {
    $('input.daterange').each(function () {
        const drops = $(this).attr("data-drops");
        const elem = $(this);

        elem.daterangepicker({
            open: "center",
            drops: drops ? drops : "up",
            maxDate: new Date(),
            startDate: moment().subtract(7, 'd'),
            endDate: moment(),
            locale: {
                cancelLabel: 'Отменить', applyLabel: 'Применить',
            }
        });

        elem.on('cancel.daterangepicker', function (ev, picker) {
            //do something, like clearing an input
            elem.val('');
        });
    })

    $(".select2").each(function () {
        $(this).select2({
            theme: "bootstrap-5"
        });
        $(this).css("width", "100%")
    })

    $(".select2-new-cosmolot-id-modal").each(function () {
        $(this).select2({
            theme: "bootstrap-5", dropdownParent: $('#new-cosmolot-id-modal')
        });
        $(this).css("width", "100%")
    })

    $(".select2-tags").each(function () {
        $(this).select2({
            theme: "bootstrap-5", tags: true
        });
        $(this).css("width", "100%")
    })

    $("input[name='invoice_status']").each(function () {
        $(this).change(function (e) {
            const container = $(this).closest(".modal-body")
            const finalSumContainer = container.find(".edit-invoice__final-sum");

            if ($(this).val() == "is_paid") {
                finalSumContainer.show();
            } else {
                finalSumContainer.hide();
            }
        })
    });

    $(".cosmolot-id-delete").each(function () {
        $(this).click(function () {
            if (confirm("Вы точно хотите удалить?")) {
                window.location.href = $(this).data('action');
            }
        });
    });

    $(".cosmolot-id-approve").each(function () {
        $(this).change(function () {
            var checked = $(this).prop("checked");

            var message = checked ? "Вы точно хотите апрувнуть Cosmolot ID" : "Вы точно хотите снять с апрува Cosmolot ID";
            var status = checked ? 1 : 0;

            if (confirm(message)) {
                window.location.href = $(this).data("action") + "?status=" + status
            }
        });
    })

    $("[data-extra-invoices-checkbox]").each(function () {
        $(this).change(function () {
            $(this).attr("disabled", true);

            const extraInvoiceAllowed = $(this).prop('checked');
            const csrf = $("[name='csrf']").attr('content');
            const url = $(this).attr('data-extra-invoices-checkbox');

            fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    extra_invoices_allowed: extraInvoiceAllowed
                }),
                headers: {
                    'X-CSRF-Token': csrf,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(json => {
                    if (extraInvoiceAllowed) {
                        alert("Теперь стример сможет заказывать доп. инвойсы.")
                    } else {
                        alert("Теперь стример не сможет заказывать доп. инвойсы.")
                    }
                    $(this).attr("disabled", false);
                }).catch(e => {
                alert("Ошибка во время выполенния")
                $(this).attr("disabled", false);
            })
        })
    })

    $("[data-invoice-approve]").each(function () {
        $(this).click(function () {
            const type = $(this).attr("data-invoice-approve-type");
            const isConfirmed = type === "1" ? confirm("Вы точно хотите апрувнуть заявку на оплату?")
                : confirm("Вы точно хотите отменить заявку на оплату?");

            if (!isConfirmed) return false;

            $(this).attr("disabled", true);

            const csrf = $("[name='csrf']").attr('content');
            const url = $(this).attr('data-invoice-approve');

            fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    is_approved: Number(type)
                }),
                headers: {
                    'X-CSRF-Token': csrf,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(json => {
                    if (type === "1") {
                        $(this).closest(".data-invoice-approve-wrapper")
                            .replaceWith('<span class="badge badge-sm bg-gradient-secondary">В процессе</span>')
                    } else {
                        $(this).closest(".data-invoice-approve-wrapper")
                            .replaceWith('<span class="badge badge-sm bg-gradient-danger">Отменён</span>')
                    }
                    $(this).attr("disabled", false);
                }).catch(e => {
                alert("Ошибка во время выполенния")
                $(this).attr("disabled", false);
            })
        })
    })

    $("[data-cosmolot-id-daily-limit]").each(function () {
        $(this).click(function (e) {
            e.preventDefault();

            $(this).attr("disabled", true);

            const cosmolotId = $(this).attr("data-cosmolot-id-daily-limit");
            const modal = $(".cosmolot-id-daily-limit-" + cosmolotId);

            const inputDailyLimit = $("input[data-daily-limit-input='" + cosmolotId + "']");
            const inputUsedDailyLimit = $("input[data-used-daily-limit-input='" + cosmolotId + "']");
            const spanDailyLimit = $("span[data-daily-limit-input='" + cosmolotId + "']");
            const spanUsedDailyLimit = $("span[data-used-daily-limit-input='" + cosmolotId + "']");

            const newDailyLimitInput = modal.find(".new_daily_limit");

            const csrf = $("[name='csrf']").attr('content');
            const url = $(this).attr('data-url');

            fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    daily_limit: Number(newDailyLimitInput.val())
                }),
                headers: {
                    'X-CSRF-Token': csrf,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(json => {
                    inputDailyLimit.val(newDailyLimitInput.val())
                    inputUsedDailyLimit.val(0)
                    spanDailyLimit.text(newDailyLimitInput.val())
                    spanUsedDailyLimit.text(0)

                    alert("Вы успешно поменяли дневой лимит.");

                    $(this).attr("disabled", false);
                }).catch(e => {
                alert("Ошибка во время выполенния")
                $(this).attr("disabled", false);
            })
        })
    });
});
